import React from "react";

function F({folders}) {
    return (
        <div>
            {
                folders.map(
                    folder =>
                        <div className="mfolder-container" key={folder.id}>
                            <div className="mfolder-caption">{folder.name}</div>
                            <div className="mfolder">
                            {folder.navItems.map(
                                item =>
                                    <div className="mitem" key={item.id}>
                                        <div className="mitem-img"><a href={item.link}><img src={item.imageLocation} className="center" alt={"alt-img"}/></a></div>
                                        <div className="mitem-caption"><a href={item.link}>{item.name}</a></div>
                                    </div>
                            )
                            }
                            </div>
                        </div>
                )
            }
        </div>
    )
}

export default F;