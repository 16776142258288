import React from "react";

function Nav({folders}) {
    return (
        <div className="nav" key="NavMenu">
            <a href="/home">Home</a>
            <div className="nav-right">
                <a href="/editor">Edit</a>
            </div>
        </div>
    )
}

export default Nav;