import React, { Component } from 'react';
import FoldersEditor from "../component/folders-editor";

export default class EditPage extends Component {

    render () {
        return (
            <div className='Editor'>
                <h1>EDITOR</h1>
                <FoldersEditor/>
            </div>
        )
    }
}