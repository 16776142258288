import AppConst from "../appConst";

class HomeApi {

    async getFolders() {
        console.log("getFolder()")
        const requestOptions = {
            method: 'GET',
            headers: {'Content-type': 'application/json' }
        }
        return fetch( AppConst.baseUrl + '/folder', requestOptions)
    }

    async addFolder(data) {
        console.log("addFolder");
        console.log(data);
        const requestOptions = {
            method: 'POST',
            headers: {'Content-type': 'application/json' },
            body: JSON.stringify(data)
        }
        console.log(requestOptions);
        return fetch(AppConst.baseUrl + '/folder', requestOptions)
    }

    async addFolderItem(data) {
        console.log("addFolderItem");
        console.log(data);
        const requestOptions = {
            method: 'POST',
            headers: {'Content-type': 'application/json' },
            body: JSON.stringify(data)
        }
        console.log(requestOptions);
        return fetch(AppConst.baseUrl + '/folder-item', requestOptions)
    }

    async deleteFolderItem(id) {
        console.log("deleteFolderItem: " + id);
        const requestOptions = {
            method: 'DELETE',
            headers: {'Content-type': 'application/json' },
        }
        return fetch(AppConst.baseUrl + '/folder-item/' + id, requestOptions)
    }

    async deleteFolder(id) {
        console.log("deleteFolderItem: " + id);
        const requestOptions = {
            method: 'DELETE',
            headers: {'Content-type': 'application/json' },
        }
        return fetch(AppConst.baseUrl + '/folder/' + id, requestOptions)
    }

    async getFolder(id) {
        console.log("getFolder: " + id);
        const requestOptions = {
            method: 'GET',
            headers: {'Content-type': 'application/json' },
        }
        return fetch(AppConst.baseUrl + '/folder/' + id, requestOptions)
    }

    async getFolderItem(id) {
        console.log("getFolderItem: " + id);
        const requestOptions = {
            method: 'GET',
            headers: {'Content-type': 'application/json' },
        }
        return fetch(AppConst.baseUrl + '/folder-item/' + id, requestOptions)
    }

    async putFolder(folder) {
        console.log("putFolder: " + folder);
        const requestOptions = {
            method: 'PUT',
            headers: {'Content-type': 'application/json' },
            body: JSON.stringify(folder)
        }
        return fetch(AppConst.baseUrl + '/folder/', requestOptions)
    }

    async putFolderItem(folderItem) {
        console.log("getFolder: " + folderItem);
        const requestOptions = {
            method: 'PUT',
            headers: {'Content-type': 'application/json' },
            body: JSON.stringify(folderItem)
        }
        return fetch(AppConst.baseUrl + '/folder-item/', requestOptions)
    }

    async getImages() {
        console.log("getImages()")
        const requestOptions = {
            method: 'GET',
            headers: {'Content-type': 'application/json' },
        }
        return fetch(AppConst.baseUrl + '/image/', requestOptions)
    }

    uploadFile(file) {
        /*
            const data = new FormData()
    console.log(this.state.selectedFile);
    data.append('file', this.state.selectedFile)
    console.log(data);
    axios.post("http://localhost:8010/api/v1/upload", data)
      .then(res => { // then print response status
        toast.success('upload success')
      })
      .catch(err => { // then print response status
        toast.error('upload fail')
      })
         */
        const data = new FormData();
        data.append("file", file);
        const requestOptions = {
            method: 'POST',
            body: data
        }
        console.log(requestOptions);
        return fetch(AppConst.baseUrl + "/uploadfile", requestOptions)
    };
}

export default new HomeApi();