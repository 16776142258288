import React, { Component } from 'react';
import F from "../component/f";
import HomeApi from "../service/home-api.js";

export default class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stat: {},
            folders: [],
            isLoading: true
        }
    }

    async componentDidMount() {
        try {
            const data = await (await HomeApi.getFolders()).json();
            this.setState({isLoading: false, folders: data})
        } catch (e) {
            console.log(e);
            this.setState({error: e, isLoading: false});
        }
    }

    render () {
        const {error, folders, isLoading} = this.state;

        if (error) {
            return (<p>{error}</p>)
        }

        if (isLoading) {
            return (<p>Loading...</p>)
        }

        return (
            <div className='Home'>
                <h1>HOME</h1>
                <F folders={folders} />
            </div>
        )
    }
}