import React, {Component} from "react";

import HomeApi from '../service/home-api';
import GridContainer from "../mdp/Grid/GridContainer";
import GridItem from "../mdp/Grid/GridItem";
import CustomInput from "../mdp/CustomInput/CustomInput";
import Button from "../mdp/CustomButtons/Button";

class FolderPage extends Component {

    emptyItem = {
        id: null,
        name: '',
        description: null,
        order: 100
    };

    constructor(props, context) {
        super(props, context);
        this.state = {
            folder: this.emptyItem
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let folder = {...this.state.folder};
        folder[name] = value;
        this.setState({folder});
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {folder} = this.state;

        try {
            if (folder.id > 0) {
                await HomeApi.putFolder(folder)
            } else {
                await HomeApi.addFolder(folder);
            }
            this.props.history.push('/editor');
        } catch (e) {
            console.log(e);
            this.setState({error: e, isLoading: false})
        }
    }

    async componentDidMount() {
        console.log("FolderPage: " + this.props.match.params);
        try {
            if (this.props.match.params.id !== 'new') {
                const data = await (await HomeApi.getFolder(this.props.match.params.id)).json();
                this.setState({folder: data, isLoading: false});
            } else {
                var d = this.emptyItem;
                d.folderId = this.props.match.params.id;
                this.setState({folder: d, isLoading: false})
            }
        } catch (e) {
            console.log(e);
            this.setState({error: e, isLoading: false})
        }
    }

    render() {
        const {folder} = this.state;

        return <div>
            <form>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <img src={folder.imageLocation} alt={"alt-img"}/>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={4}>
                        <CustomInput id={"editName"} labelText={"name"} formControlProps={{fullWidth: true}}
                                     inputProps={{
                                         name: "name",
                                         onChange: this.handleChange,
                                         value: folder.name
                                     }}/>
                    </GridItem>
                    <GridItem xs={2} sm={2} md={2}>
                        <CustomInput id={"editOrder"} labelText={"order"} formControlProps={{fullWidth: true}}
                                     inputProps={{
                                         name: "order",
                                         onChange: this.handleChange,
                                         value: folder.order
                                     }}/>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={4}>
                        <CustomInput id={"editImageLocation"}
                                     labelText={"image"}
                                     formControlProps={{fullWidth: true}}
                                     inputProps={{
                                         name: "imageLocation",
                                         onChange: this.handleChange,
                                         value: folder.imageLocation
                                     }}/>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                                     labelText={"description"}
                                     formControlProps={{fullWidth: true}}
                                     inputProps={{
                                         name: "description",
                                         onChange: this.handleChange,
                                         value: folder.description
                                     }}/>
                    </GridItem>
                    <GridItem xs={12}>
                        <Button color={"success"} onClick={this.handleSubmit}>OK</Button>
                        <Button color={"secondary"} href={"/editor"}>Cancel</Button>
                    </GridItem>

                </GridContainer>
            </form>
        </div>
    }
}

export default FolderPage;