import React, {Component} from "react";
import HomeApi from '../service/home-api.js';
import Button from "../mdp/CustomButtons/Button.js";
import GridContainer from "../mdp/Grid/GridContainer";
import GridItem from "../mdp/Grid/GridItem";

import { makeStyles } from "@material-ui/core/styles";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Tab from "@material-ui/icons/Tab";

import styles from "../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

class FoldersEditor extends Component {
    constructor(props) {
        super();

        this.state = {
            folders: [],
            isLoading: true
        }

        this.onDeleteItem = this.onDeleteItem.bind(this);
        this.onEditItem = this.onEditItem.bind(this);
        this.handleDeleteFolderItem = this.handleDeleteFolderItem.bind(this);
    }

    async componentDidMount() {
        try {
            const data = await (await HomeApi.getFolders()).json()
            this.setState({folders: data, isLoading: false});
        } catch (e) {
            console.log(e);
            this.setState({error: e, isLoading: false})
        }
    }

    onDeleteItem(e) {
        console.log("onDeleteItem:" + e);
        console.log(e.key);
    }

    onEditItem(e) {
        console.log("onEditItem:" + e);
        console.log(e.key);
    }

    handleDeleteFolderItem(event) {
        event.preventDefault();
        console.log(event);
        const target = event.target;
        const name = target.name;
        HomeApi.deleteFolderItem(name);
    }

    handleDeleteFI(id) {
        HomeApi.deleteFolderItem(id);

        var folders = this.state.folders;
        folders.forEach(f => f.navItems = f.navItems.filter(item => item.id !== id));
        this.setState({folders: folders});
    }

    async handleDeleteFolder(id) {
        try {
            const r =await HomeApi.deleteFolder(id);
            alert(r.status + ": " + r.statusText);
            const folders = this.state.folders.filter(item => item.id !== id);
            this.setState({folders: folders});
        } catch (e) {
            console.log(e);
            this.setState({error: e})
        }
    }

    render() {
        const classes = useStyles;
        console.log(classes);

        const {folders, isLoading, error} = this.state;

        if (error) {
            return (<p>{error}</p>)
        }

        if (isLoading) {
            return(<p>Loading...</p>);
        }

        return(
            <div className="folders-editor">
                {
                    folders.map(
                        folder =>
                            <div key={folder.id} className={"box1"}>
                                    <GridContainer className={"box2"}>
                                        <GridItem xs={1}><Tab color={"secondary"}/></GridItem>
                                        <GridItem xs={7}><h2>{folder.name}</h2></GridItem>
                                        <GridItem xs={1}><Button color={"primary"} href={"/folder/new/"}>Add folder</Button></GridItem>
                                        <GridItem xs={1}><Button color={"primary"} href={"/folder-item/new/"+folder.id}>Add item</Button></GridItem>
                                        <GridItem xs={1}><Button color={"primary"} href={"/folder/" + folder.id}>Edit folder</Button></GridItem>
                                        <GridItem xs={1}><Button color={"danger"} onClick={() => {this.handleDeleteFolder(folder.id)}}>Delete folder</Button></GridItem>
                                    </GridContainer>
                                    {folder.navItems.map(
                                        item =>
                                            <div className={"box2"} key={item.id}>
                                                <GridContainer>
                                                    <GridItem xs={2}><img src={item.imageLocation} alt={item.name} /></GridItem>
                                                    <GridItem xs={6} direction={"column-reverse"}>
                                                        <div className={"f1-container"}>
                                                            <span>name: {item.name}</span>
                                                            <span>link: {item.link}</span>
                                                            <span>image location: {item.imageLocation}</span>
                                                            <span>order: {item.order}</span>
                                                        </div>
                                                        <br/>
                                                    </GridItem>
                                                    <GridItem xs={4} justify-content={"center"}>
                                                        <ButtonGroup>
                                                            <Button color={"primary"} href={"/folder-item/"+item.id}>Edit</Button>
                                                            <Button color={"danger"} onClick={() => {this.handleDeleteFI(item.id)}} name={item.id}>Delete</Button>
                                                        </ButtonGroup>
                                                    </GridItem>
                                                </GridContainer>
                                            </div>
                                        )}
                            </div>
                    )
                }
            </div>
        )
    }

}

export default FoldersEditor;