import React, {Component} from "react";
import GridContainer from "../mdp/Grid/GridContainer";
import GridItem from "../mdp/Grid/GridItem";
import CustomInput from "../mdp/CustomInput/CustomInput";

/*import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";*/
import Button from "../mdp/CustomButtons/Button.js";

import HomeApi from "../service/home-api.js";
import ImageUpload from "../mdp/CustomUpload/ImageUpload";

//const useStyles = makeStyles(styles);

class FolderItemPage extends Component {

    emptyItem = {
        id: null,
        name: '',
        folderId: 0,
        imageLocation: "",
        link: "",
        order: 100
    };

    constructor(props) {
        super(props);
        this.state = {
            item: this.emptyItem,
            isLoading: true,
            error: null,
            images: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleImgClick = this.handleImgClick.bind(this);
    }

    async componentDidMount() {
        console.log("FolderItemPage: " + this.props.match.params);
        try {
            // images
            const dataImages = await (await HomeApi.getImages()).json();
            console.log(dataImages);

            // data
            if (this.props.match.params.id !== 'new') {
                const data = await (await HomeApi.getFolderItem(this.props.match.params.id)).json();
                this.setState({item: data, isLoading: false, images: dataImages});
            } else {
                var d = this.emptyItem;
                d.folderId = this.props.match.params.folderId;
                this.setState({item: d, isLoading: false, images: dataImages})
            }
        } catch (e) {
            console.log(e);
            this.setState({error: e})
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let item = {...this.state.item};
        item[name] = value;
        this.setState({item});
        console.log(name);
    }

    handleImgClick(event) {
        event.preventDefault();
        const target = event.target;
        const name = target.name;
        let item = {...this.state.item};
        item.imageLocation = name;
        this.setState({item});
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {item} = this.state;

        try {
            if (item.id > 0) {
                await HomeApi.putFolderItem(item)
            } else {
                await HomeApi.addFolderItem(item)
            }
            this.props.history.push('/editor');
        } catch (e) {
            console.log(e);
            this.setState({error: e})
        }
    }

    render() {
        const {error, item, isLoading, images} = this.state;

        if (error) {
            return (<p>{error}</p>)
        }

        if (isLoading) {
            return (<p>Loading...</p>)
        }

        return <div>
            <form>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <img src={item.imageLocation} alt={"alt-img"}/>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={4}>
                        <CustomInput id={"editName"} labelText={"name"} formControlProps={{fullWidth: true}}
                                     inputProps={{
                                         name: "name",
                                         onChange: this.handleChange,
                                         value: item.name
                                     }}/>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={4}>
                        <CustomInput id={"editLink"} labelText={"link"} formControlProps={{fullWidth: true}}
                                     inputProps={{
                                         name: "link",
                                         onChange: this.handleChange,
                                         value: item.link
                                     }}/>
                    </GridItem>
                    <GridItem xs={2} sm={2} md={2}>
                        <CustomInput id={"editOrder"} labelText={"order"} formControlProps={{fullWidth: true}}
                                     inputProps={{
                                         name: "order",
                                         onChange: this.handleChange,
                                         value: item.order
                                     }}/>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={4}>
                        <CustomInput id={"editImageLocation"}
                                     labelText={"image"}
                                     formControlProps={{fullWidth: true}}
                                     inputProps={{
                                         name: "imageLocation",
                                         onChange: this.handleChange,
                                         value: item.imageLocation
                                     }}/>
                    </GridItem>
                    <GridItem xs={12}>
                        <Button color={"success"} onClick={this.handleSubmit}>OK</Button>
                        <Button color={"secondary"} href={"/editor"}>Cancel</Button>
                    </GridItem>
                </GridContainer>
            </form>
            <GridContainer>
                <GridItem xs={12}>
                    <ImageUpload/>
                </GridItem>
                {images.map(item =>
                    <GridItem xs={4} sm={1}><img src={item.fileUrl} onClick={this.handleImgClick} name={item.fileUrl} key={item.id} alt={item.name}/></GridItem>
                )}
            </GridContainer>
        </div>
    }
}

export default FolderItemPage;