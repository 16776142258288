import React, {Component} from 'react';
import {
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import './App.css';

import Nav from "./component/nav.jsx";
import Home from "./page/Home";
import EditPage from "./page/EditPage";
import FolderItemPage from "./page/FolderItemPage";
import FolderPage from "./page/FolderPage";

class App extends Component{

  render () {
    const {history} = this.props;

    return(
        <div className="App">
          <Nav />
          <Switch>
            <Route history={history} path="/home" component={Home}/>
            <Route history={history} path="/editor" component={EditPage}/>
            <Route history={history} path="/folder-item/:id/:folderId?" component={FolderItemPage}/>
            <Route history={history} path="/folder/:id" component={FolderPage}/>
            <Redirect from="/" to="/home"/>
          </Switch>
        </div>
    )
  }


}

/*
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}*/

export default App;
